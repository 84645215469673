
















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { contacts } from 'td-api'
import { teamsStore, callsStore, activeCallStore, meetingsStore, uiStore } from '@/store'
import { Meeting, Contact, MeetingsUpdateRequest, MeetingsUpdateRequestJSON } from '@tada-team/tdproto-ts'
import { ChatEventBus, Events as ChatEvents } from '@/components/Chat/ChatEventBus'
import * as Sentry from '@sentry/browser'

@Component({
  components: {
    MeetingArea: () => import('./Area/index.vue'),
    ChatArea: () => import('@/components/MiddleColumn/FullTask/ChatArea.vue'),
    MeetingSplit: () => import('./Split.vue'),
    NotifyCallBar: () => import('@/components/Calls/NotifyCallBar.vue'),
    IconLogoGray: () => import('@/components/UI/icons/IconLogoGray.vue'),
  },
})
export default class MiddleColumnMeeting extends Vue {
  private owner: Contact | null = null

  private isSmallMeetingArea = true
  private importantViewerActive = false
  private loadingError = false

  mounted () {
    ChatEventBus.$on(ChatEvents.TOGGLE_IMPORTANT_MESSAGES_VIEWER, this.importantViewerActivated)
  }

  beforeDestroy () {
    ChatEventBus.$off(ChatEvents.TOGGLE_IMPORTANT_MESSAGES_VIEWER, this.importantViewerActivated)
  }

  get meeting (): Meeting | null {
    if (!this.meetingId) return null
    return meetingsStore.state.loadedMeetings[this.meetingId]
  }

  get meetingId () {
    return meetingsStore.state.selectedMeetingUUID
  }

  get hasSelectedMessagesIds () {
    const hasSelected = uiStore.getters.selectedMessagesIds && uiStore.getters.selectedMessagesIds.length > 0
    return hasSelected
  }

  async created (): Promise<void> {
    if (!this.meetingId) return
    await this.loadData(this.meetingId)
  }

  private async loadData (meetingId: string): Promise<void> {
    try {
      await meetingsStore.actions.loadMeeting(meetingId)
      this.loadingError = false
    } catch (e) {
      this.loadingError = true
    }
    if (this.meeting && this.meeting.ownerContactUuid) await this.loadOwner(this.meeting.ownerContactUuid)
  }

  get meetingLoadingError () {
    return this.$t('meetings.meeting.errorAccessDenied')
  }

  private async loadOwner (ownerUUid: string): Promise<void> {
    this.owner = await contacts.getContact(teamsStore.getters.currentTeam.uid, ownerUUid)
  }

  @Watch('meetingId')
  async updateMeeting (meetingId: string) {
    await this.loadData(meetingId)
  }

  private changeSplitMode (value: boolean): void {
    this.isSmallMeetingArea = value
  }

  get canCall () {
    return this.meeting?.canCall && callsStore.getters.isCallingEnabled()
  }

  get calling () {
    if (!this.canCall || !this.meeting) return

    const activeCallJid = activeCallStore.state.activeCall?.jid
    const isActiveCallInThisChat = (
      activeCallJid &&
      activeCallJid === this.meeting.jid
    )

    return (
      isActiveCallInThisChat ||
      callsStore.getters.call(this.meeting.jid)
    )
  }

  private importantViewerActivated (): void {
    this.importantViewerActive = !this.importantViewerActive
  }

  private async changeNotificationMode (): Promise<void> {
    if (!this.meeting) return
    await this.updateChangeableFields({
      notificationsEnabled: !this.meeting.notificationsEnabled,
    })
  }

  private async changeCountersEnabled (): Promise<void> {
    if (!this.meeting) return
    await this.updateChangeableFields({
      countersEnabled: !this.meeting.countersEnabled,
    })
  }

  private async updateChangeableFields (p: { notificationsEnabled?: boolean, countersEnabled?: boolean}): Promise<void> {
    if (!this.meetingId || !this.meeting) return
    const { notificationsEnabled, countersEnabled } = p
    try {
      const params: MeetingsUpdateRequestJSON = {
        team_uuid: teamsStore.getters.currentTeam.uid,
        meeting_id: this.meetingId,
        notifications_enabled: notificationsEnabled,
        counters_enabled: countersEnabled,
      }
      await meetingsStore.actions.updateMeeting(MeetingsUpdateRequest.fromJSON(params))
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('meetings', 'update meeting')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
  }
}
